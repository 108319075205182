import { Route, Routes, useNavigate } from 'react-router-dom';
import React, { Suspense, useState } from 'react';
import './App.css';
import Header from './components/Header';
import Layout from './pages/home/Layout';
import Event from './pages/home/Event';
import Home from './pages/home/home/Home';
import PrivateRoute from './components/PrivateRoute';
import Footer from './components/Footer';
import VerifyMessage from './pages/auth/VerifyMessage';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { googleAuthSuccess, logout } from './redux/authSlice';
import LoaderGift from './components/Gifts/loaderGift/LoaderGift';
import Confirmed from './pages/auth/Confirmed';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import BetaInfo from './components/BetaInfo';


const EventDetail = React.lazy(() => import('./pages/home/detail/EventDetail'));
const AdviceForm = React.lazy(() => import('./components/adviceForm/AdviceForm'));
const AddEvent = React.lazy(() => import('./pages/admin/AddEvent'));
const EditEvent = React.lazy(() => import('./pages/admin/EditEvent'));
const Register = React.lazy(() => import('./pages/auth/Register'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Logout = React.lazy(() => import('./pages/auth/Logout'));
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'));
const ChangePassword = React.lazy(() => import('./pages/auth/ChangePassword'));

const AdminEvents = React.lazy(() => import('./pages/admin/adminEvents/AdminEvents'));
const UserEvents = React.lazy(() => import('./pages/admin/UserEvents'));


function App() {

  const message = useSelector(state => state.event.message);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (message == "Token Bulunmamaktadır !") {
      dispatch(logout());
      navigate('/');
    }
  }, [message]);


  const [showButton, setShowButton] = useState(false);
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // const eventvers_date = localStorage.getItem("eventvers_date");
    // if (eventvers_date) {
    //   //console.log("var")
    // } else {
    //   //console.log("yok")
    //   const google = localStorage.getItem("google");
    //   if (google) {
    //     localStorage.removeItem("google");
    //     dispatch(googleAuthSuccess());
    //   }
    // }
  }, []);


  return (
    <div className="App">
      <BetaInfo />
      <Header />
      <div id='pages' style={{ position: 'relative' }}>
        <Suspense fallback={<Loader />}>
          <Routes>

            <Route index={true} element={<Home />}></Route>
            <Route path='/events' element={<Event />}></Route>
            <Route path='/event/:eventId' element={<EventDetail />}></Route>
            <Route path='/contact' element={<AdviceForm />}></Route>

            {/* Admin */}
            <Route path='/add_event' element={<PrivateRoute roles={["admin","user"]}> <AddEvent /> </PrivateRoute>}></Route>
            <Route path='/edit_event/:eventId' element={<EditEvent />}></Route>
            <Route path='/admin_event' element={<PrivateRoute roles={["admin"]}><AdminEvents /></PrivateRoute>}></Route>
            <Route path='/user_event' element={<PrivateRoute roles={["admin", "user"]}><UserEvents /></PrivateRoute>}></Route>

            {/* Auth */}
            <Route path='/verify_message' element={<VerifyMessage />}></Route>
            <Route path='/confirmed' element={<Confirmed />}></Route>
            <Route path='/register' element={<Register />}></Route>
            <Route path='/login' element={<Login />}></Route>
            <Route path='/logout' element={<Logout />}></Route>
            <Route path='/forgot_password' element={<ForgotPassword />}></Route>
            <Route path='/change_password/:token' element={<ChangePassword />}></Route>
          </Routes>
        </Suspense>
      </div>
      <div style={{ position: 'fixed', zIndex: 5, bottom: 40, right: 35 }} >
        {showButton && <button id='topToScrollButton' onClick={scrollToTop}><KeyboardDoubleArrowUpIcon /></button>}
      </div>
      <Footer />
    </div>
  );
}
const Loader = () => {
  return (
    <div id='loader-container' >
      <LoaderGift color={"rgb(247, 108, 57)"} />
    </div>)
}
export default App;
