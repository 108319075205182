import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { api } from "../utility/api";

const initialState = {
    userEvents: [],
    loading: false,
    err: ""
}

export const getUserEvents = createAsyncThunk('getUserEvents', async () => {
    try {
        const response = await api().get('/user/events', { withCredentials: true });
        console.log(response);
        return response.data;
    } catch (err) {
        console.log(err);
    }
})
export const deleteUserEvent = createAsyncThunk('deleteEvent', async (data) => {
    console.log(data);
    try {
        const response = await api().delete(`/event/${data.id}`, { withCredentials: true });
        return { response: response.data, id: data.id };
    } catch (err) {
        console.log(err);
    }
})

const user = createSlice({
    name: 'user',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getUserEvents.pending, (state, action) => {
            state.loading = true;
            state.err = ""
        })
        builder.addCase(getUserEvents.fulfilled, (state, action) => {
            state.loading = false;
            state.userEvents = action.payload;
        })
        builder.addCase(getUserEvents.rejected, (state, action) => {
            state.loading = false;
            state.err = "Kullanıcı etkinliklerini getirirken bir hata oluştu !"
        })
        builder.addCase(deleteUserEvent.pending, (state, action) => {
            state.loading = true;
            state.error = '';
        })
        builder.addCase(deleteUserEvent.fulfilled, (state, action) => {
            if (action.payload.response.status == "success") {
                state.loading = false;
                state.userEvents = state.userEvents.filter(e => e.id !== action.payload.id);
            }
            // const message = action.payload.response.message
            // state.message = message !== undefined ? message : " ";
        })
        builder.addCase(deleteUserEvent.rejected, (state, action) => {
            state.loading = false;
            state.error = 'Etkinliği silerken bir hata oluştu !';
        })
    }
})

export default user.reducer;